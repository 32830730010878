.common_dialog_box .common_title[data-v-3a38ecd4] {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
  font-weight: 600;
  line-height: 36px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}
.fxk_div[data-v-3a38ecd4] {
  width: 100%;
}
.el-dialog__body[data-v-3a38ecd4] {
  padding-top: 0 !important;
}
.big_box[data-v-3a38ecd4] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
}
.box[data-v-3a38ecd4] {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
}
.image[data-v-3a38ecd4] {
  width: 30px;
  height: 30px;
}
[data-v-3a38ecd4] .el-checkbox__label {
  display: flex !important;
  justify-content: center !important;
  height: 15px !important;
}
.choose_img[data-v-3a38ecd4] {
  border-radius: 50px;
}
.div_box[data-v-3a38ecd4] {
  display: flex;
  align-items: center;
}
.div_box img[data-v-3a38ecd4] {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.top_select[data-v-3a38ecd4] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}